module.exports = {
  siteTitle: 'ddns.eus | Euskal DNS Dinamikoa', // <title>
  siteDescription: 'Euskal DNS Dinamikoa',
  siteKeywords: '',
  manifestName: 'Fractal',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#FBF9BF',
  manifestThemeColor: '#FBF9BF',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  // pathPrefix: `/gatsby-starter-fractal/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'www.ddns.eus',
  subHeading: 'Euskal DNS Dinamikoa',
  headingimg: '',
  // social
  socialLinks: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/ddnseus',
    },
    // {
    //   icon: 'fa-facebook',
    //   name: 'Facebook',
    //   url: 'https://facebook.com/di_stribut_io',
    // },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:info@ddns.eus',
    },
  ],
  phone: '000-00000',
  address: '',
  languages: {
    langs: ['en', 'es', 'eu'],
    defaultLangKey: 'eu'
  }
};
