import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <footer id="footer">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { icon, url, name } = social;
          return (
            <li key={url}>
              <a className={`icon ${icon}`} href={url}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <p className="copyright">
        &copy; DDNS.eus | <a href="https://www.patreon.com/ddnseus" className="icon fa-heart"> Proudly built in the Basque Country </a>
        <br/><span>by using www.ddns.eus you agree to our <a href="/tos">Terms of Service</a> and <a href="/privacy">Privacy Policy</a></span>
      </p>
    </footer>
  );
}
